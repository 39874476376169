
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { cloneDeep } from 'lodash';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import PriorityListItem from '@/ui/components/modals/ManagePriorities/PriorityListItem.vue';
import draggable from 'vuedraggable';
import {
  allEnergyCircleTypes, EnergyCircleType,
} from '@/types/energyVisualisation/EnergyCircleType';
import { IDevice } from '@/types/devices.types';
import { Action, Mutation } from 'vuex-class';
import EnergyComponent
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/utils/EnergyComponent';
import { IReportBox } from '@/types/app.types';
import { IPrioritiesList } from '@/types/common.types';
import { IPublish } from '@/types/mqtt.types';

@Component({
  computed: {
    EnergyCircleType: () => EnergyCircleType,
  },
  components: {
    ModalWindow,
    draggable,
    PriorityListItem,
  },
})
export default class ManagePriorities extends mixins(EnergyComponent) {
  @Prop() deviceData!: IDevice;
  @Mutation('app/setReport') setReport!: (payload: IReportBox) => void;
  @Action('mqttClient/publish') publish!: IPublish;

  list: IPrioritiesList[] = [];

  get allSystemsInstances() {
    return this.filterPresentSystems(this.deviceData, allEnergyCircleTypes)
      .flatMap((system: EnergyCircleType) => {
        return Object
          .entries(this.getSystemComponents(this.deviceData, system))
          .map(([systemInstance, mappings]) => ({ system, systemInstance, mappings }));
      });
  }
  get systemInstancesWithPriority() {
    return this.allSystemsInstances.filter(({ mappings }: Record<string, any>) => mappings?.priority);
  }

  sortByPriority(arr: IPrioritiesList[]) {
    return arr.sort((a: IPrioritiesList, b: IPrioritiesList) => {
      const priorityA = this.measurements[a.mappings.priority] as number;
      const priorityB = this.measurements[b.mappings.priority] as number;
      if (priorityA > priorityB) return 1;
      if (priorityA < priorityB) return -1;
      return 0;
    });
  }

  onDialogOpen(status: boolean) {
    if (status) {
      this.list = cloneDeep(this.systemInstancesWithPriority);
      this.sortByPriority(this.list);
    }
  }

  onSave() {
    this.list.forEach((item: IPrioritiesList, inx: number) => {
      this.publish({ records: [{ v: inx + 1, n: item.mappings.priority, u: '' }] });
    });
    this.setReport({
      type: 'success',
      message: 'Priorities updated',
      value: true,
    });

    (this.$refs.ModalWindowInstance as ModalWindow).closeDialog();
  }
}
