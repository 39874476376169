
import { Vue, Component, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';
import ChargeStationMinPowerSelection
  from '@/ui/components/devices/devices/base/ChargeStationMinPowerSelection.vue';
import LabelUnitWrapper from '@/ui/components/devices/components/LabelUnitWrapper.vue';
import { IDevice } from '@/types/devices.types';
import { IVariablesState } from '@/store/modules/measurements/types';
import {
  energyCircleIconForType,
} from '@/types/energyVisualisation/EnergyCircleType';
import { IPrioritiesList } from '@/types/common.types';

@Component({
  methods: { energyCircleIconForType },
  components: {
    ChargeStationMinPowerSelection,
    LabelUnitWrapper,
  },
})
export default class PriorityListItem extends Vue {
  @Prop() expandable?: boolean;
  @Prop({
    default: { system: '', systemInstance: '', mappings: {} },
  }) priorityListItemData!: IPrioritiesList;
  @Prop() deviceData!: IDevice;
  @State('measurements') measurementsState!: IVariablesState;

  expand = false;

  get measurements() {
    return this.measurementsState.measurements;
  }
}
